<template>
    <div>
       <banner></banner>
       <div class="newslist">
			
			<div class="container">
				 <div class="news" v-for="(item,i) in list" :key="i" @click="tourl(item.id)">
                    <div class="img">
					    <img :src="item.img" :alt="item.title">
                    </div>
                    <div class="conent">
                        <div class="c1">
                            <h4>{{ item.title }}</h4>
                            <p>{{item.describe}}</p>
                        </div>
                        <div  class="c2">
                            <p>
                                <span>来源：{{ item.source }}</span>
                                <span>时间：{{ item.create_time }}</span>
                            </p>
                            <p>阅读全文</p>
                        </div>

                    </div>
                 </div>

				<el-pagination  background  layout="prev, pager, next" 
                :total="total" :page-size="pageSize" :current-page="pageNum" :hide-on-single-page="pageSize<=10">
                </el-pagination>
			</div>
           
		</div>
    </div>
    
</template>
    
<script>
import banner from '@/components/banner.vue'
import {getnewslistAPI} from "@/api/common.js"
export default {
   components: {
        banner
        
    },
    data () {
        return {
            total:0,
            pageSize: 10,
            pageNum: 1,
            list:[]
        }
    },
    created () {
        this.getlist()
    },
    methods: {
        getlist(){
            var data={
                pageSize: this.pageSize,
                pageNum: this.pageNum
            }
            getnewslistAPI(data).then(res=>{
                this.list=res.list
                this.total=res.count
            })
        },
        tourl(id){
            this.$router.push({ path: '/newsinfo',query:{id} })
        }
    }
}
</script>
    
<style lang="scss" scoped>
.newslist{
    width: 100%;
    min-width: 1200px;        
    padding:30px 50px; 
    .news{
        display: flex;
        align-items: center;
        margin-top: 20px;
        margin-bottom: 20px;
        cursor: pointer;
        .img{
            width: 300px;
            height: 140px;
            background-color: #efefef;
            overflow: hidden;
            border-radius: 5px;
            img{
                width: 100%;
                border-radius: 5px;
            }
        }
        .conent{
            flex: 1;
            margin-left: 20px;
            height: 140px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .c1{
                p{
                    font-size: 14px;
                    line-height: 24px;
                    color: #666666;
                }
            }
            .c2{
                display: flex;
                justify-content: space-between;
                font-size: 12px;
                color: #999999;
                span{
                    color: #999999;
                    margin-right: 10px;
                }
                p{
                    color: #999999;
                }
            }
        }
    }
    

}
</style>
    